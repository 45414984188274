import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { PAGE_TEMPLATE } from '../../services/api/constants'
import { LAYOUT_TEMPLATE } from '../../services/api/types'

export const LocationSearchWrapper = styled.div<{ template?: LAYOUT_TEMPLATE }>`
  ${tw`relative flex flex-col lg:flex-row lg:mx-auto w-full justify-center items-center bg-grey__light py-4`}

  ${props =>
    props.template === PAGE_TEMPLATE.YOU_FITNESS &&
    css`
      ${tw`md:py-2.5 bg-white__deep`}

      ${SearchLabel} {
        ${tw`uppercase text-black text-center mr-0 md:mr-4 md:text-3xl`}
      }

      ${SearchFormGroup} {
        & > button {
          ${tw`bg-blue__deep text-sm not-italic`}
        }
      }
    `}
`
export const FormLabel = styled.label<{ isYouFitness?: boolean }>`
  ${tw`font-medium`}
  ${({ isYouFitness }) => {
    return isYouFitness ? tw`text-white mb-2` : tw`text-blue__dark`
  }}
`

export const ErrorMessage = styled.span`
  ${tw`text-red__pinterest mt-2`}
`

export const SearchLabel = styled.label`
  ${tw`font-display text-blue__dark text-lg font-bold mr-4`}
`

export const SearchFormGroup = styled.div`
  ${tw`relative flex items-center justify-center`}

  & > button {
    ${tw`h-full lg:mx-2`}
    padding: 0px 1rem;
    height: 40px;
  }
`
export const MultilineSelect = styled.select`
, .Select-option {
  white-space: pre-wrap;
}
`
export const WrapLocationSearch = styled.div`
  ${tw`relative flex flex-col w-full sm:max-w-screen-md font-display`}
  padding: 0 !important;
  overflow: visible;
`

export const FormGroup = styled.div<{ col?: number; paddingX?: number }>`
  ${tw`relative flex flex-col items-start mb-4`}

  & > input {
    ${tw`w-full bg-grey__light`}
  }
  ${({ col }) => {
    if (col === 1) return tw`w-full`
    else if (col === 2) return tw`lg:w-2/5`
    else if (col === 3) return tw`lg:w-1/3`
    else return ''
  }}
  ${({ paddingX }) => {
    if (paddingX === 1) return tw`lg:px-1`
    else if (paddingX === 2) return tw`lg:px-2`
    else if (paddingX === 3) return tw`lg:px-3`
    else if (paddingX === 4) return tw`lg:px-4`
    else if (paddingX === 5) return tw`lg:px-5`
    else if (paddingX === 6) return tw`lg:px-6`
    else return ''
  }}
`

export const Form = styled.form`
  ${tw`m-2`}
  z-index: 1;
`

export const NewsSearchWrapper = styled.div<{ template?: LAYOUT_TEMPLATE }>`
  ${tw`w-full`}

  ${props =>
    props.template === PAGE_TEMPLATE.YOU_FITNESS &&
    css`
      ${NewsSearchGroup} {
        & > button {
          ${tw`text-blue__deep not-italic`}
        }
      }

      ${NewsSearchTextInput},
      ${NewsSearchContainer} {
        ${tw`bg-blue__deep rounded-none`}
      }
    `}
`

export const NewsSearchContainer = styled.form`
  ${tw`w-full relative m-auto flex items-center bg-orange py-2 text-white `}
`

export const NewsSearchGroup = styled(SearchFormGroup as any)`
  ${tw`justify-around w-full`}

  & > button {
    ${tw`text-orange focus:outline-none lg:w-40`}
    padding: 0.45rem 0.5rem;
  }
`

export const LabelIcon = styled.label`
  ${tw`hidden md:block text-2xl px-4`}
`

export const NewsSearchTextInput = styled.input`
  ${tw`bg-orange md:w-5/6 lg:w-full border-b-2 h-8 text-white font-display outline-none`}

  &::placeholder {
    ${tw`text-white`}
  }
`

export const FranchiseeSearchWrapper = styled.div`
  ${tw`relative flex flex-col items-start lg:items-end w-full md:w-1/2 lg:w-1/3`}
  & > form {
    ${tw`w-full`}
  }
`

export const FranchiseeSearchGroup = styled(SearchFormGroup as any)`
  ${tw`w-full flex`}

  & > input {
    ${tw`w-full`}
  }
  & > button {
    ${tw`focus:outline-none`}
    height: 40px;
  }
`
