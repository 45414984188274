import { useEffect, useState, useContext } from 'react'
import { BlockTitle, Button, TextInput} from '../base'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  LocationSearchWrapper,
  SearchFormGroup,
  SearchLabel
} from './SearchForm.styled'
import * as G from '../../styles/global.styles'
import { LAYOUT_TEMPLATE, GymSelectOption } from '../../services/api/types'
import * as S from './SearchForm.styled'
import { EnquiryFormRow } from '../forms/MemberEnquiryForm.styled'
import { getCookieFromBrowser } from '../../services/api/cookies'
import { submitForm } from '../../services/api/cms'
import { PAGE_TEMPLATE, generalItemsPerPage } from '../../services/api/constants'
import { Col, Row } from '../../styles/grid.styles'
import Select from 'react-select'
import { getAllGyms } from '../../services/api/cms'
import { colors } from '../../styles/theme'
import { getSiteCode } from '../../services/api/helpers'
import { SiteContext } from '../../services/context/SiteContext'



export interface LocationSearchFormProps {
  searchLabel?: string
  searchPlaceHolder: string
  searchButtonText?: string
  searchQuery?: string
  onQueryChange?: Function
  searchOnTyping?: boolean
  template?: LAYOUT_TEMPLATE
  gym?: number
  suburbStep?: boolean
  handleSuburbStep?: () => void
}


export const LocationSearchForm = (props: LocationSearchFormProps) => {
  const {
    searchLabel,
    searchButtonText,
    searchPlaceHolder,
    searchQuery,
    onQueryChange,
    searchOnTyping,
    template,
    gym,
    suburbStep,
  } = props

  const { siteInfor } = useContext(SiteContext)
  const siteCode = getSiteCode(siteInfor?.root_url)
  const [searchTerm, setSearchTerm] = useState<string>(searchQuery || '')
  const [gym_list, setGymList] = useState<GymSelectOption[]>()
  const [selectedGym, setSelectedGym] = useState<GymSelectOption>()
  const [postcode, setPostcode] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const onSearch = (e?: any) => {
    e && e.preventDefault()
    onQueryChange && onQueryChange(searchTerm)
  }
  const textInputChanged = (e: any) => {
    setSearchTerm(e.target.value)
    if (searchOnTyping) {
      onQueryChange && onQueryChange(e.target.value)
    }
  }
  const FindGym = (id: any) => {
    if(gym_list){
      if(gym_list.find(item=>item.value === id)){
        return gym_list.find(item=>item.value === id)
      }
    }
  }
  const setGymSelection = (id: number) => {
    if(id){
      let gym_item = FindGym(id)
      formik.setFieldValue('gym_signup', id)
      setSelectedGym(gym_item)
    }
  }
  const PostcodeInputChanged = async (searchKey: string) => {
    const data = await getAllGyms({
      per_page: generalItemsPerPage,
      q: searchKey,
      ordering: 'title',
    })
    let gyms: Array<GymSelectOption> = []
    data.results.map((gym_item: any) => {
      let label = gym_item.address_street+" "+gym_item.address_suburb+" "+gym_item.address_postcode+" "+gym_item.address_state_code

      if(gym){
        if(gym == gym_item.id){
            let selected_gym = {
              label: String(gym_item.title) || '',
              address: String(label),
              postcode: String(gym_item.address_postcode) || '',
              url: String(gym_item.url) || '',
              value: Number(gym_item.id) || 0,
            }
            setSelectedGym(selected_gym)
            if(!searchKey){
              formik.setFieldValue('postCode', gym_item.address_postcode)              
              setPostcode(gym_item.address_postcode)
              formik.setFieldValue('gym_signup', gym_item.id)
            }else{
              setPostcode(searchKey)
              formik.setFieldValue('postCode', searchKey)
            }

        }
      }
      gyms.push({
        value: gym_item.id || 0,
        label: gym_item.title,
        address: label,
        postcode: gym_item.address_postcode,
        url: gym_item.join_url,
      })
    })
    setGymList(gyms)

  }  
  const selectedStyles = () => ({
    "> .address": {
      display: "none"
    }
  });
  const colourStyles = {
    control: () => ({
      cursor: 'pointer',
      height: '40px;',
      display: 'flex',
      borderRadius: 1,            
    }),
    placeholder: () => ({
      color: `${colors.black}`
    }),
    option: () => ({
      borderBottom: `1px solid ${colors.grey__light}`,
      cursor: 'pointer',
      padding: 12,
      "&:hover": {
        backgroundColor: `${colors.grey__light}`,
      },      
    }),
    container: () => ({
      width: '100%',
      fontSize: 15,
      backgroundColor: `${colors.white__deep}`,
    }),
    singleValue: () => ({ ...selectedStyles})
  };
  const formik = useFormik({

    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      gym_signup: '',
      postCode: ''
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required('First Name is required'),
      gym_signup: Yup.string().required('Gym is required'),
      lastName: Yup.string().required('Last Name is required'),
      phone: Yup.string().required('Phone is required'),
      email: Yup.string()
        .email('Invalid email')
        .required('Email is required')
    }),
    onSubmit: async (values: any) => {
      setIsSubmitting(true)
      let submitted_gym = values.gym_signup
      let gym_url = '' 
      let selected_gym=FindGym(submitted_gym)
      if(selected_gym && selected_gym.url){
        gym_url=selected_gym.url
      }
      let reqParams: any = {}
      const utmSourceCookie = await getCookieFromBrowser('utm_source')
      const utmCampaignCookie = await getCookieFromBrowser('utm_campaign')
      if (utmSourceCookie && utmCampaignCookie) {
        reqParams['utm_source'] = utmSourceCookie
        reqParams['utm_campaign'] = utmCampaignCookie
      }
      reqParams['first-name'] = values.firstName
      reqParams['last-name'] = values.lastName
      reqParams['email-address'] = values.email
      reqParams['phone-number'] = values.phone
      reqParams['postcode'] = postcode
      reqParams['select-your-gym'] = values.gym_signup

      let to_form=`generic-prospect-form`
      if(siteCode ==  'au'){
        to_form =`join-now-prospect`
      }else if (siteCode == 'nz' ){
        to_form =`join-now-prospect-nz`
      }else if (siteCode == 'in' ){
        to_form =`join-now-prospect-in`
      }else if (siteCode == 'id' ){
        to_form =`join-now-prospect-id`
      }

      try {
        await submitForm(null, to_form, reqParams)
        setTimeout(() => {
          window.location.assign(gym_url)
          setIsSubmitting(false)
        }, 500)
      } catch (error) {
        alert('Somethings went wrong!')
      }
    }
  })
  const formatOptionLabel = ({ label, address, status }: any, { context }: any) =>
  context === "value" ? (
    <div>{label}</div>
  ) : (
    <div style={{ padding: `0px 0px` }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h5
          className="location"
          style={{
            fontSize: 16,
            lineHeight: `23px`,
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          {label}
        </h5>
        <div className="status" style={{ color: "#EE6C16"}}>
          {status}
        </div>
      </div>
      {address && (
        <p
          className="address"
          style={{
            marginBottom: 0,
            textAlign: "left",
            color: "grey",
          }}
        >
          {address}
        </p>
      )}
    </div>
  );

  useEffect(() => {
    PostcodeInputChanged && PostcodeInputChanged(formik.values.postCode)
  }, [formik.values.postCode])

  const isMobile = window.innerWidth <= 768

  const getMenuPlacement = () => {
    return isMobile ? 'top' : 'bottom'
  };

  return (
    <>
      {!suburbStep ? (
        <LocationSearchWrapper
          template={template}
          className='location-search-form'
        >
          <SearchLabel htmlFor='searchText'>{searchLabel}</SearchLabel>
          <G.FormWrapper onSubmit={onSearch}>
            <SearchFormGroup>
              <TextInput
                id='searchText'
                color='black'
                className='w-64'
                onChange={textInputChanged}
                placeholder={searchPlaceHolder}
                value={searchTerm}
              />
              {searchButtonText && (
                <Button type='submit'>{searchButtonText}</Button>
              )}
            </SearchFormGroup>
          </G.FormWrapper>
        </LocationSearchWrapper>
      ) : (
        <>
          <S.WrapLocationSearch>
            <BlockTitle isYouFitness={template === PAGE_TEMPLATE.YOU_FITNESS}>
            LET’S GET STARTED
            </BlockTitle>
            <S.Form onSubmit={formik.handleSubmit}>
            <Row>
            <Col md={6}>
              <S.FormGroup col={6} paddingX={2}>
                <S.FormLabel
                  isYouFitness={template === PAGE_TEMPLATE.YOU_FITNESS}
                >
                  First Name
                </S.FormLabel>
                <TextInput
                  name='firstName'
                  color='black'
                  borderColor='white'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <S.ErrorMessage>
                  {formik.errors.firstName &&
                    formik.touched.firstName &&
                    formik.errors.firstName}
                </S.ErrorMessage>
                </S.FormGroup>
              </Col>
              <Col md={6}>
              <S.FormGroup col={6} paddingX={2}>
                <S.FormLabel
                  isYouFitness={template === PAGE_TEMPLATE.YOU_FITNESS}
                >
                  Last Name
                </S.FormLabel>
                <TextInput
                  name='lastName'
                  color='black'
                  borderColor='white'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <S.ErrorMessage>
                  {formik.errors.lastName &&
                    formik.touched.lastName &&
                    formik.errors.lastName}
                </S.ErrorMessage>
              </S.FormGroup>
              </Col>
              <Col md={6}>
              <S.FormGroup col={6} paddingX={2}>
                <S.FormLabel
                  isYouFitness={template === PAGE_TEMPLATE.YOU_FITNESS}
                >
                  Email Address
                </S.FormLabel>
                <TextInput
                  name='email'
                  color='black'
                  borderColor='white'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <S.ErrorMessage>
                  {formik.errors.email &&
                    formik.touched.email &&
                    formik.errors.email}
                </S.ErrorMessage>
              </S.FormGroup>
              </Col>
              <Col md={6}>
              <S.FormGroup col={6} paddingX={2}>
                <S.FormLabel
                  isYouFitness={template === PAGE_TEMPLATE.YOU_FITNESS}
                >
                  Phone Number
                </S.FormLabel>
                <TextInput
                  type='tel'
                  name='phone'
                  color='black'
                  borderColor='white'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <S.ErrorMessage>
                  {formik.errors.phone &&
                    formik.touched.phone &&
                    formik.errors.phone}
                </S.ErrorMessage>
              </S.FormGroup>
              </Col>
              <Col md={12}>
                <S.FormGroup paddingX={2}>
                <S.FormLabel
                    isYouFitness={template === PAGE_TEMPLATE.YOU_FITNESS}
                  >
                    Gym
                  </S.FormLabel>
                  <Select
                    options={gym_list}
                    value={selectedGym}
                    name='gym_signup'
                    styles={colourStyles}
                    isMulti={false}
                    formatOptionLabel={formatOptionLabel} // pass the custom menu option component
                    classNamePrefix="lp-copy-sel"
                    onBlur={formik.handleBlur}
                    onChange={(event: any) =>
                      setGymSelection(event.value)
                    }
                    placeholder='Select a gym'
                    menuPlacement={getMenuPlacement()}
                    noOptionsMessage={() =>
                      'Enter your postcode to select a gym'
                    }
                    />
                  <S.ErrorMessage>
                    {formik.errors.gym_signup &&
                      formik.touched.gym_signup &&
                      formik.errors.gym_signup}
                  </S.ErrorMessage>
                </S.FormGroup>
              </Col>
              <EnquiryFormRow justify='center'>
                <Button
                  color={
                    template === PAGE_TEMPLATE.YOU_FITNESS ? 'blue-deep' : ''
                  }
                  type='submit'
                  disabled={isSubmitting}
                >
                  CONTINUE
                </Button>
              </EnquiryFormRow>
              </Row>
            </S.Form>
          </S.WrapLocationSearch>
          {isSubmitting ? (
            <G.SpinnerOverlayWrap>
              <G.SpinnerWrap
                isYouFitness={template === PAGE_TEMPLATE.YOU_FITNESS}
                isCenter={true}
              >
                <G.Spinner />
              </G.SpinnerWrap>
            </G.SpinnerOverlayWrap>
          ) : null}
        </>
      )}
      </>
    )
  }

export default LocationSearchForm
